body{
    color: #111;
    font-family: 'Raleway', sans-serif;
}

.select-account{
    margin-top: 40px !important;
}

.row-border{
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    /* box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important; */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .description{
        display: none;
    }

    /* .border{
        display: none;
    } */

    .row-border{
        border-bottom: 1px solid #e5e5e5;
        border-radius: 8px;
    }
  }
